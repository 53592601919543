// src/pages/SigninPage.tsx

import React, { useState, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import LinearProgress from '@mui/joy/LinearProgress';
import { useTheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

import useGoogleSignIn from './hooks/useGoogleSignIn';
import LoginDrawerComponent from './components/LoginDrawerComponent';
import BrandFooterComponent from '../../components/BrandFooterComponent';
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const SigninPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = useThemeSwitcher();
  const navigate = useNavigate();

  const { isAuthenticated, isLoading, userBusinessId, userRole } =
    useContext(AuthContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const { handleGoogleSignIn } = useGoogleSignIn();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      if (userBusinessId == null && userRole !== 'super-admin') {
        navigate('/admin/onboarding/');
      } else {
        navigate('/admin/events/');
      }
    }
  }, [isLoading, isAuthenticated, navigate, userBusinessId, userRole]);

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  if (isLoading) {
    return <LinearProgress color="neutral" variant="outlined" />;
  }

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          ></Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            }}
          >
            <Stack gap={2}>
              <img
                src={`/Logotype_${mode}.svg`}
                alt="Orbiner Logo"
                style={{ height: '24px', width: 'auto', alignSelf: 'start' }}
              />
              <Typography level="h1" sx={{ textAlign: 'left' }}>
                <Trans
                  i18nKey="signUpOrLogin"
                  components={[
                    <span style={{ color: theme.vars.palette.primary.accentColor }} />,
                  ]}
                />
              </Typography>

              <Button
                variant="outlined"
                color="neutral"
                onClick={handleGoogleSignIn}
                startDecorator={
                  <img
                    src="/google_icon.svg"
                    alt="Google Logo"
                    style={{ height: '24px', width: 'auto' }}
                  />
                }
              >
                {t('googleAuth')}
              </Button>
              <Button
                variant="plain"
                startDecorator={
                  <span className="material-symbols-outlined" style={{ color: 'black' }}>
                    arrow_back
                  </span>
                }
                size="sm"
                onClick={() => window.open('https://orbiner.com', '_blank')}
                sx={{ color: 'black', fontWeight: 'normal', alignContent: 'left' }}
              >
                {t('goBacktoSite')}
              </Button>
            </Stack>
          </Box>
          {/* Footer */}
          <Box
            component="footer"
            sx={{
              pb: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 'auto',
              justifyContent: 'flex-start',
              gap: 1,
              mx: 'auto',
            }}
          >
            <BrandFooterComponent mode={mode} showDate={true} />
            <Box onClick={() => toggleDrawer(true)} style={{ cursor: 'pointer' }}>
              <Typography level="body-md">{'- adminlogin'} </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          m: 5,
          height: '50vh',
          position: 'fixed',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/signin_illustration.png)',
        }}
      />
      <LoginDrawerComponent isOpen={isDrawerOpen} onClose={() => toggleDrawer(false)} />
    </CssVarsProvider>
  );
};

export default SigninPage;
