// hooks/useEventsByLocation.js

import { useState, useEffect } from 'react';
import { API } from '../../../utils/API';
import { useQuery } from '@tanstack/react-query';
import { EventInfo } from '../../../types/eventTypes';

const useEventsByLocation = (location: string | null) => {
  const [events, setEvents] = useState<EventInfo[] | null>([]);

  // Use React Query to fetch event information
  const {
    data,
    isLoading: isEventsLoading,
    isError: isEventsError,
    error: eventsError,
  } = useQuery<EventInfo[]>({
    queryKey: ['eventInfo', location !== null],
    queryFn: async () => {
      const response = await API.get<EventInfo[]>(`/api/v1/events/location/${location}`);
      return response.data;
    },
    enabled: !!location, // Only if location is provided
  });

  // Handle successful data fetch
  useEffect(() => {
    // console.log(data);
    if (data) {
      setEvents(data);
    } else {
      setEvents([]);
    }
  }, [data]);

  return { events, isEventsLoading, isEventsError };
};

export default useEventsByLocation;
