import React from 'react';
import { Box, Typography, Switch, Button } from '@mui/joy';
import { EventInfo } from '../../../types/eventTypes';

import { useTranslation } from 'react-i18next';
import useBusinessInfo from '../../../hooks/useBusinessInfo';

interface PyroInfoComponentProps {
  eventInfo: EventInfo;
  isTestMode: boolean;
  isArmed: boolean;
  handleArmed: (isArmed: boolean) => void;
  isLaunched: boolean;
  handleLaunchMusic: () => void;
}

const PyroInfoComponent: React.FC<PyroInfoComponentProps> = ({
  eventInfo,
  isTestMode = false,
  isArmed = false,
  handleArmed,
  isLaunched = false,
  handleLaunchMusic,
}) => {
  const { t } = useTranslation();

  const { businessInfo } = useBusinessInfo(`public/${eventInfo.businessId}`); // Retrieve public information from business

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        position: 'relative',
        px: 2,
        pb: 1,
        gap: 1,
      }}
    >
      <Typography sx={{ whiteSpace: 'pre-line', flex: 1 }}>
        {isTestMode
          ? ''
          : t('pyroScheduledTime', {
              scheduledTime: new Date(eventInfo.scheduledTime!).toLocaleString(),
            })}
      </Typography>

      {/* <Typography
        component="label"
        startDecorator={
          <Switch
            variant={isArmed ? 'solid' : 'outlined'}
            onChange={e => handleArmed(e.target.checked)}
            checked={isArmed && !isTestMode}
            disabled={isTestMode} // Switch is disabled when in Test
          />
        }
        sx={{ flex: 'none', whiteSpace: 'nowrap' }}
      >
        {t('armedEvent')}
      </Typography> */}
      {/* Custom Switch Armed  */}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Switch
          size="lg"
          checked={!!(isArmed && !isTestMode)} // cast in boolean, isArmed can be not boolean
          onChange={e => handleArmed(e.target.checked)}
          variant="solid"
          color={isTestMode ? 'warning' : isArmed ? 'primary' : 'neutral'}
          // color="warning"
          disabled={isTestMode} // Switch is disabled when in Test
          slotProps={{
            input: { 'aria-label': 'Dark mode' },
            thumb: {
              children: (
                <React.Fragment>
                  <span className="material-symbols-outlined">
                    {isArmed && !isTestMode ? 'safety_check' : 'safety_check_off'}
                    {/* {isArmed && !isTestMode ? 'play_arrow' : 'pause'} */}
                    {/* {isArmed && !isTestMode ? 'music_note' : 'music_off'} */}
                    {/* {isArmed && !isTestMode ? 'public' : 'public_off'} */}
                    {/* {isArmed && !isTestMode ? 'record_voice_over' : 'voice_over_off'} */}
                  </span>
                  {/* {isArmed && !isTestMode ? t('armedEvent') : t('unArmedEvent')} */}
                  {/* Armed */}
                </React.Fragment>
              ),
            },
            track: {
              children: (
                <React.Fragment>
                  <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                    {t('armedEvent')}
                  </Typography>
                  <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                    {t('unArmedEvent')}
                  </Typography>
                </React.Fragment>
              ),
            },
          }}
          sx={{
            '--Switch-thumbSize': '32px',
            '--Switch-thumbWidth': '90px',
            '--Switch-trackWidth': '180px',
            '--Switch-trackHeight': '40px',
            py: 1,
          }}
        />
        <Button
          onClick={handleLaunchMusic}
          variant="outlined"
          color={isTestMode ? 'warning' : 'primary'}
          disabled={!isTestMode && !isArmed} // SetScheduledTime is disabled if not Armed in PublicMode
          size="sm"
        >
          {isTestMode
            ? isLaunched
              ? 'stop test'
              : t('changeTestTime')
            : isLaunched
              ? 'stop music'
              : t('changeScheduledTime')}
        </Button>
      </Box>
      {!isLaunched && (
        <Typography>
          {isTestMode
            ? t('testModeDescription', { businessShortName: businessInfo?.shortName })
            : isArmed
              ? t('eventIsArmedPyroInfo')
              : t('publicModeDescription')}
        </Typography>
      )}
    </Box>
  );
};

export default PyroInfoComponent;
