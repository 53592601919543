// QRCodeWithLogos.tsx
import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import QRCodeComponent from './QRCodeComponent';
import { useQRDownload } from '../hooks/useQRDownload';
import { useTranslation } from 'react-i18next';

interface QRCodeWithLogosProps {
  link: string;
  imageName: string;
  size: number;
  QRCodeTitle?: string | null;
  businessLogo?: string | null;
  orbinerLogo: string;
}

const QRCodeWithLogos: React.FC<QRCodeWithLogosProps> = ({
  link,
  imageName,
  size,
  QRCodeTitle,
  businessLogo,
  orbinerLogo,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { downloadQRImage } = useQRDownload();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDownload = async () => {
    await downloadQRImage(containerRef, imageName);
  };

  return (
    <Box
      sx={{
        transition: 'all 0.3s',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
          '& .qrCode': {
            filter: 'blur(10px)',
          },
          '& .hoverText': {
            display: 'flex',
          },
        },
      }}
      onClick={handleDownload}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        ref={containerRef}
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
          borderRadius: '8px',
          backgroundColor: 'white',
        }}
        component="div"
        className="qrCode"
      >
        {/* <div id="qrCode" className="qrCode"> */}
        <Typography sx={{ py: 1 }}>{QRCodeTitle}</Typography>
        <QRCodeComponent link={link} size={size} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mt: 1,
          }}
        >
          <img
            src={orbinerLogo}
            alt="Orbiner Logo"
            style={{ height: '16px', objectFit: 'contain' }}
          />
          {businessLogo && (
            <img
              src={businessLogo}
              alt="Business Logo"
              style={{ height: '16px', objectFit: 'contain' }}
            />
          )}
        </Box>
        {/* </div> */}
      </Box>
      {isHovered && (
        <Typography
          className="hoverText"
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
          }}
        >
          {t('download')}
        </Typography>
      )}
    </Box>
  );
};

export default QRCodeWithLogos;
