import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Snackbar,
  useTheme,
  useColorScheme,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
// import QRCodeComponent from './QRCodeComponent';
import QRCodeWithLogosComponent from './QRCodeWithLogosComponent';

interface LinkSectionProps {
  title: string;
  link: string;
  imageName: string;
  QRCodeTitle: string;
  businessLogo: string | null;
}

const LinkSectionComponent: React.FC<LinkSectionProps> = ({
  title,
  link,
  imageName,
  QRCodeTitle,
  businessLogo,
}) => {
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode } = useColorScheme();

  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setSuccessSnackBarOpen(true);
    } catch (error) {
      console.error('Failed to copy text:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <>
      <Typography level="body-md">{title}</Typography>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          mx: 'auto',
          border: '1px solid',
          borderColor: 'divider',
          width: 'fit-content',
          borderRadius: '8px',
        }}
      >
        <QRCodeWithLogosComponent
          link={link}
          imageName={imageName}
          size={128}
          QRCodeTitle={QRCodeTitle}
          businessLogo={businessLogo}
          orbinerLogo={`/Logotype_${mode}.svg`}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor:
              mode === 'dark' ? theme.palette.primary[600] : theme.palette.primary[50],
            borderRadius: 'md',
            border: '1px solid',
            borderColor: 'divider',
            width: 'fit-content',
            maxWidth: '100%', // Prevent overflow on the link box itself
            overflow: 'hidden', // Prevent overflow
          }}
        >
          {/* Copy text button */}
          <IconButton
            aria-label="copy"
            onClick={handleCopyText}
            sx={{ width: 12, height: 12 }}
          >
            <span className="material-symbols-outlined" style={{ fontSize: '14px' }}>
              content_copy
            </span>
          </IconButton>
          <Divider orientation="vertical" sx={{ height: 'auto', alignSelf: 'stretch' }} />
          {/* Link */}
          <Typography
            sx={{
              mx: 1,
              textAlign: 'center',
              fontSize: '14px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {link}
          </Typography>
        </Box>
        {/* Open in New window */}
        <IconButton
          onClick={() => window.open(link, '_blank')}
          variant="outlined"
          className="action-icons"
        >
          <span className="material-symbols-outlined">open_in_new</span>
        </IconButton>
      </Box>
      <Snackbar
        autoHideDuration={800}
        open={successSnackBarOpen}
        variant="outlined"
        color="primary"
        size="sm"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={() => setSuccessSnackBarOpen(false)}
      >
        <Typography>{t('linkCopiedSnackbar')}</Typography>
      </Snackbar>
    </>
  );
};

export default LinkSectionComponent;
