// EventsPage.tsx

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Typography,
  Box,
  Autocomplete,
  CircularProgress,
  FormLabel,
  Button,
  Select,
  Option,
  Sheet,
} from '@mui/joy';

import useCityAutocomplete from '../adminevents/hooks/useCityAutocomplete';
import useEventsByLocation from './hooks/useEventsByLocation';
import { formatDate } from '../../utils/formatUtils';

import { EventInfo } from '../../types/eventTypes';

const SearchEventPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [inputCity, setInputCity] = useState<string>('');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);
  const [hasTouched, setHasTouched] = useState<boolean>(false);

  const [message, setMessage] = useState<string | null>(null);

  const [selectedEvent, setSelectedEvent] = useState<EventInfo | null>(null);
  // const [noEventsFound, setNoEventsFound] = useState<boolean>(false);

  const { citiesOption, isLoadingLocation, fetchCities } = useCityAutocomplete();

  // Debounce the API call
  useEffect(() => {
    if (inputCity === '' || isLocationSelected) return;

    const timer = setTimeout(() => {
      // console.log('fetching cities');
      fetchCities(inputCity);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [inputCity, fetchCities, isLocationSelected]);

  // Fetch events based on selected location
  const { events, isEventsLoading, isEventsError } =
    useEventsByLocation(selectedLocation);

  // Handle event selection
  useEffect(() => {
    if (isLocationSelected && selectedLocation && events) {
      // console.log(events.length);
      if (events.length === 0) {
        setMessage(t('messageMissingEvent'));
      } else if (events.length === 1) {
        // Only one event found, set it as selected
        setSelectedEvent(events[0]);
      } else {
        // Multiple events found, clear any previously selected event
        setMessage(t('messageMultipleEvent'));
        setSelectedEvent(null);
      }
    }
  }, [isLocationSelected, selectedLocation, events]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Top Container Logo + Button */}
      <Box
        sx={{
          p: 4,
          maxWidth: '1440px',
          width: '100%',
          mx: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 10,
        }}
      >
        <Box
          component="img"
          src="/Logotype_dark.svg"
          alt="Logo"
          sx={{
            maxHeight: '24px',
          }}
        />
        <Button
          variant="plain"
          endDecorator={
            <span className="material-symbols-outlined" style={{ color: 'white' }}>
              arrow_forward
            </span>
          }
          sx={{
            color: 'white',
            textTransform: 'none',
            fontWeight: 'normal',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
          onClick={() => window.open('https://orbiner.com', '_blank')}
        >
          {t('goBacktoSite')}
        </Button>
      </Box>

      {/*  Title  */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          mx: 'auto',
          px: 6,
          maxWidth: 'min(70vw, 1440px)',
        }}
      >
        <Typography
          component="h1"
          level="h1"
          sx={{
            textAlign: 'left',
            zIndex: 10,
            color: 'white',
            fontSizeAdjust: '0.7',
          }}
        >
          {t('searchEventsByLocationHeading')}
        </Typography>
      </Box>

      {/* Bottom Box */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pb: 3,
          mx: 'auto',
          width: '100%',
          maxWidth: '600px',
          zIndex: 10,
          px: 1,
        }}
      >
        {/* Message on top */}
        {message && (
          <Sheet
            color="primary"
            sx={{
              py: 2,
              px: 3,
              borderRadius: 'sm',
              mb: 1,
              width: '100%',
              backgroundColor: 'rgba(31, 8, 105, 0.5)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <Typography
              color="primary"
              fontSize="sm"
              sx={{ color: 'rgba(244, 242, 255, 1)' }}
            >
              {message}
            </Typography>
          </Sheet>
        )}

        {/* Responsive container: on large screens row, on small screens column */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: 2,
            width: '100%',
          }}
        >
          {/* Left container with Autocomplete, error messages and Select if needed */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              width: '100%',
            }}
          >
            <FormLabel
              htmlFor={'events-search'}
              sx={{
                color: 'white',
                mb: 1,
              }}
            >
              {t('city')}
            </FormLabel>

            {/* Search Bar */}
            <Autocomplete
              freeSolo
              inputValue={inputCity}
              onInputChange={(_, newInputCity, reason) => {
                if (reason === 'input') {
                  setInputCity(newInputCity);
                } else if (reason === 'clear') {
                  setMessage(null); // reset message
                }
                setIsLocationSelected(false);
                setHasTouched(false);
              }}
              onChange={(_, newCity) => {
                if (newCity && typeof newCity === 'object' && newCity.nom) {
                  setHasTouched(true);
                  const newLocation = newCity.nom;
                  setInputCity(newLocation);
                  setIsLocationSelected(true);
                  setSelectedLocation(newLocation);
                  setSelectedEvent(null);
                  setMessage(null); // reset message
                } else if (typeof newCity === 'string') {
                  setHasTouched(true);
                  setInputCity(newCity);
                  setIsLocationSelected(false);
                  setSelectedLocation(null);
                  setSelectedEvent(null);
                  setMessage(null); // reset message
                } else {
                  setHasTouched(false);
                  setInputCity('');
                  setIsLocationSelected(false);
                  setSelectedLocation(null);
                  setSelectedEvent(null);
                }
              }}
              options={citiesOption}
              getOptionLabel={option =>
                typeof option === 'string' ? option : option.nom
              }
              loading={isLoadingLocation}
              startDecorator={
                <span className="material-symbols-outlined" style={{ color: 'white' }}>
                  search
                </span>
              }
              endDecorator={
                isLoadingLocation || isEventsLoading ? (
                  <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                ) : null
              }
              size="lg"
              sx={{
                flexGrow: 1,
                width: '100%',
                minWidth: '250px',
                backgroundColor: 'rgba(244, 242, 255, 0.15)',
                backdropFilter: 'blur(10px)',
                borderColor: 'rgba(244,242,255,0.6)',
                color: 'rgba(244, 242, 255, 1)',
                '& .MuiAutocomplete-input': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
            />

            {/* Validation Error if wrong text entered */}
            {!isLocationSelected && hasTouched && (
              <Typography color="danger" fontSize="sm" sx={{ mt: 1 }}>
                {t('cityError')}
              </Typography>
            )}

            {/* Error fetching events */}
            {isEventsError && (
              <Typography color="danger" fontSize="sm" sx={{ mt: 1 }}>
                {t('errorFetchingEvents')}
              </Typography>
            )}

            {/* Dropdown to select Dates if multiple events */}
            {events && events.length > 1 && (
              <Box sx={{ mt: 2 }}>
                <FormLabel
                  htmlFor={'date-select'}
                  sx={{
                    color: 'white',
                    mb: 1,
                  }}
                >
                  {t('date')}
                </FormLabel>
                <Select
                  onChange={(event, newValue) => {
                    const selected = events.find(e => e.publicLink === newValue);
                    setSelectedEvent(selected || null);
                    setMessage(null);
                  }}
                  size="lg"
                  sx={{
                    width: '100%',
                    minWidth: '250px',
                    backgroundColor: 'rgba(244, 242, 255, 0.15)',
                    backdropFilter: 'blur(10px)',
                    borderColor: 'rgba(244,242,255,0.6)',
                    color: 'rgba(244, 242, 255, 1)',
                    '& .MuiAutocomplete-input': {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(244, 242, 255, 0.15)',
                    },
                    '&.Mui-focused': {
                      backgroundColor: 'rgba(244, 242, 255, 0.15)',
                    },
                  }}
                >
                  {events.map(event => (
                    <Option key={event.publicLink} value={event.publicLink}>
                      {formatDate(event.scheduledTime!)}
                    </Option>
                  ))}
                </Select>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            {/* Right container with CTA */}

            <Button
              variant="outlined"
              size="lg"
              color="primary"
              sx={{
                flexShrink: 0,
                minWidth: '250px',
              }}
              onClick={() => {
                if (!isLocationSelected) {
                  setMessage(t('messageMissingLocation'));
                } else if (events && events.length > 1 && !selectedEvent) {
                  setMessage(t('messageMissingDate'));
                } else if (selectedEvent) {
                  navigate(`/events/${selectedEvent.publicLink}`);
                } else {
                  setMessage(t('messageMissingEvent'));
                }
              }}
              endDecorator={
                <span className="material-symbols-outlined">arrow_forward</span>
              }
            >
              {t('gotoEvent')}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Background Image */}
      <Box
        component="img"
        src="/events_bg.jpg"
        alt="Background of a firework"
        sx={{
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />
      {/* Background Linear Overlay */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          // backgroundImage:
          //   'linear-gradient(rgba(255, 255, 255, 0),rgba(244, 242, 255, 0.3))',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0),rgba(40, 12,130, 0.5))',
          zIndex: 2,
        }}
      />
    </Box>
  );
};

export default SearchEventPage;
