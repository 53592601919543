import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';

import EventsTable from './components/EventsTable';
import SideBarComponent from '../../components/SideBarComponent';
import EventModalComponent from './EventModalComponent';
import ConfirmationModalComponent from './components/ConfirmationModalComponent';
import ShareModalComponent from './components/ShareModalComponent';

import useEventList from './hooks/useEventList';
import useEventDeletion from './hooks/useEventDeletion';
import useEventPayment from './hooks/useEventPayment';
import useEventSharing from './hooks/useEventSharing';

import { CssBaseline, Box, Button, Stack, Snackbar, Typography } from '@mui/joy';
import { EventInfo } from '../../types/eventTypes';

interface AdminEventsPageProps {}

const AdminEventsPage: React.FC<AdminEventsPageProps> = () => {
  const { t } = useTranslation();
  const { userBusinessId } = useContext(AuthContext);

  const [selectedEvent, setSelectedEvent] = useState<EventInfo | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState<boolean>(false);

  // Custom hook to fetch all events
  const { events, loading, error } = useEventList();

  // Hooks for event selection and modals

  const openEventModal = (event?: EventInfo) => {
    setSelectedEvent(event || undefined);
    setIsEditing(!!event);
    setIsEventModalOpen(true);
  };

  const closeEventModal = (isSuccess: boolean) => {
    if (isSuccess) {
      setIsEventModalOpen(false);
      setSelectedEvent(undefined);
      setIsEditing(false);
      // Open successSnackBar if success
      setSuccessSnackBarOpen(true);
    }
  };

  // Event Deletion hook
  const {
    confirmationModalOpen,
    eventToDelete,
    initiateDeleteEvent,
    confirmDeleteEvent,
    cancelDeleteEvent,
  } = useEventDeletion();

  // Event Payment hook
  const { initiatePayment } = useEventPayment();

  // Hook for event sharing
  const { isShareModalOpen, eventToShare, openShareModal, closeShareModal } =
    useEventSharing();

  // const handleCreateEvent = () => {
  //   openEventModal();
  // };

  // Handle Edit / Delete
  const handleEditEvent = (eventId: string) => {
    const event = events.find(e => e.id === eventId);
    if (event) {
      openEventModal(event);
    }
  };

  const handleDeleteEvent = (eventId: string) => {
    const event = events.find(e => e.id === eventId);
    if (event) {
      initiateDeleteEvent(event);
    }
  };

  const handleShareEvent = (eventId: string) => {
    const event = events.find(e => e.id === eventId);
    // console.log(event);
    if (event) {
      openShareModal(event);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideBarComponent settings={false} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '1em',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
          <h2>{t('events')}</h2>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Button onClick={() => openEventModal()} variant="soft" color="primary">
              {t('createEvent')}
            </Button>
          </Stack>
        </Box>
        <Box className="admin-table">
          {events.length > 0 ? (
            <EventsTable
              events={events}
              onEditEvent={handleEditEvent}
              onDeleteEvent={handleDeleteEvent}
              onShareEvent={handleShareEvent}
              onPayEvent={initiatePayment}
            />
          ) : (
            <div>{t('noEvents')}</div>
          )}
        </Box>
        <EventModalComponent
          open={isEventModalOpen}
          handleClose={closeEventModal}
          isEditing={isEditing}
          eventData={selectedEvent || undefined}
        />
        <ConfirmationModalComponent
          open={confirmationModalOpen}
          handleClose={cancelDeleteEvent}
          handleDelete={confirmDeleteEvent}
          eventToDeleteName={eventToDelete?.name || ''}
        />
        {eventToShare && (
          <ShareModalComponent
            open={isShareModalOpen}
            handleClose={closeShareModal}
            eventData={eventToShare || undefined}
            userBusinessId={userBusinessId || null}
          />
        )}
        <Snackbar
          autoHideDuration={1000}
          open={successSnackBarOpen}
          variant="soft"
          color="success"
          size="sm"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setSuccessSnackBarOpen(false)}
        >
          <Typography>{t('eventSuccessSnackBar')}</Typography>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default AdminEventsPage;
