// components/WelcomeModalComponent.js
import React from 'react';
import {
  Modal,
  ModalDialog,
  DialogContent,
  DialogTitle,
  Button,
  Stack,
  Box,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { EventInfo } from '../types/eventTypes';

import QRCodeComponent from './QRCodeComponent';

interface QRCodeModalComponentProps {
  open: boolean;
  handleClose: () => void;
  eventData: EventInfo;
  isPyroPage: boolean;
  size: number;
}

const QRCodeModalComponent: React.FC<QRCodeModalComponentProps> = ({
  open,
  handleClose,
  eventData,
  isPyroPage,
  size,
}) => {
  const { t } = useTranslation();
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="share-modal-title"
      sx={{ backdropFilter: 'blur(10px)' }}
    >
      <ModalDialog variant="outlined" color="primary" sx={{ maxWidth: '100%' }}>
        <DialogTitle>{t('shareEventTitle')}</DialogTitle>
        <DialogContent>
          {/* Lien Public */}
          {!isPyroPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <QRCodeComponent
                link={`${frontendUrl}/events/${eventData.publicLink}`}
                // imageName={`${eventData.publicLink}`}
                size={size}
              />
            </Box>
          )}
          {/* Lien Pyro */}
          {isPyroPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <QRCodeComponent
                link={`${frontendUrl}/events/pyro/${eventData.id}`}
                // imageName={`Pyro_${eventData.publicLink}`}
                size={size}
              />
            </Box>
          )}
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="plain" color="neutral" onClick={handleClose}>
              {t('close')}
            </Button>
          </Stack>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default QRCodeModalComponent;
