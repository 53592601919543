// useQRDownload.js
import html2canvas from 'html2canvas';
import { RefObject } from 'react';

export function useQRDownload() {
  const downloadQRImage = async (
    elementRef: RefObject<HTMLElement>,
    imageName: string
  ) => {
    if (!elementRef.current) return;

    // Use html2canvas to snapshot the element
    const canvas = await html2canvas(elementRef.current, {
      backgroundColor: null,
      scale: 2,
    });

    const pngUrl = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${imageName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return { downloadQRImage };
}
