import { useState, useEffect, useRef } from 'react';
import { usePostHog } from 'posthog-js/react';

export interface UseEventAudioReturn {
  isMusicPlaying: boolean;
  isMusicFinished: boolean;
  toggleMute: () => void;
  isMuted: boolean;
  isMusicError: boolean;
}

const useEventAudio = (
  musicPath: string | null,
  audioContext: AudioContext | null,
  playbackDelta: number | null,
  deltaScheduledWServer: number | null,
  isTimecode: boolean,
  timecodeChannel: boolean,
  isArmed: boolean,
  isEventLoading: boolean,
  isPyro?: boolean
): UseEventAudioReturn => {
  // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
  // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
  // const [audioBuffer, setAudioBuffer] = useState(null);
  const [musicBuffer, setMusicBuffer] = useState<AudioBuffer | null>(null);

  // Music Status
  const [isMusicLoaded, setIsMusicLoaded] = useState<boolean>(false);
  const [isMusicPlaying, setIsMusicPlaying] = useState<boolean>(false); // false means music is off initially
  const [isMusicFinished, setIsMusicFinished] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isMusicError, setIsMusicError] = useState<boolean>(false);
  const channelIndex = (channel: boolean) => (channel ? 1 : 0);

  const timeoutRefStart = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timeoutRefEnd = useRef<ReturnType<typeof setTimeout> | null>(null);

  // State to hold the current nodes
  const musicNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const [musicNode, setMusicNode] = useState<AudioBufferSourceNode | null>(null);

  // GainNode for muting the music
  const gainNodeRef = useRef<GainNode | null>(null);

  const posthog = usePostHog();

  // Logic for preloading Audio
  useEffect(() => {
    // Check if required parameters are available
    if (isEventLoading || !musicPath || !audioContext) {
      console.log('useEventAudio event not loaded yet');
      return;
    }
    const preloadAudio = (
      url: string,
      setAudioBuffer: React.Dispatch<React.SetStateAction<AudioBuffer | null>>,
      setIsAudioLoaded: React.Dispatch<React.SetStateAction<boolean>>,
      retryCount: number = 3
    ) => {
      // console.log(url);
      fetch(url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          // console.log(url)
          if (arrayBuffer.byteLength === 0) {
            throw new Error('Array buffer is empty.');
          }
          // Check if the audio context is suspended
          if (audioContext.state === 'suspended') {
            // console.log('Audio Context Suspended :',arrayBuffer.byteLength)
            return audioContext.resume().then(() => arrayBuffer);
          }
          // console.log('Audio Context was not suspended', arrayBuffer.byteLength);
          return arrayBuffer;
        })
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(decodedAudioBuffer => {
          setAudioBuffer(decodedAudioBuffer);
          setIsAudioLoaded(true);
          // setAudioDuration(decodedAudioBuffer.duration)
          // console.log('Music preloaded |o/')
        })
        .catch(error => {
          // console.error('Error preloading audio:', error);
          if (retryCount > 0) {
            // console.log(`Retrying... ${retryCount} attempts left`);
            // console.log(url)
            setTimeout(
              () => preloadAudio(url, setAudioBuffer, setIsAudioLoaded, retryCount - 1),
              10000
            ); // Retry after 10 seconds
          } else {
            setIsMusicError(true);
          }
        });
    };

    if (musicPath && audioContext) {
      preloadAudio(musicPath, setMusicBuffer, setIsMusicLoaded);
    }

    return () => {
      // Clean up the music node if it's playing
      if (musicNodeRef.current) {
        musicNodeRef.current.stop();
        musicNodeRef.current.disconnect();
        musicNodeRef.current = null;
      }
      setIsMusicLoaded(false);
    };
  }, [musicPath]);

  // Logic to launch Audio playback
  useEffect(() => {
    // Stop nodes
    if (musicNode) {
      musicNode.stop();
    }
    if (musicNodeRef.current) {
      musicNodeRef.current.stop();
    }
    setIsMusicPlaying(false);

    if (
      !isEventLoading &&
      isMusicLoaded &&
      musicBuffer &&
      audioContext &&
      playbackDelta !== null &&
      deltaScheduledWServer !== null
    ) {
      // If the scheduled time is in the future, schedule playback using audioContext's built
      try {
        // console.log('scheduling playback');
        schedulePlayback(
          playbackDelta,
          musicBuffer,
          audioContext!,
          deltaScheduledWServer!,
          isArmed
        );
      } catch (error) {
        setIsMusicError(true);
      }
    }

    function schedulePlayback(
      playbackDelta: number,
      musicBuffer: AudioBuffer,
      audioContext: AudioContext,
      deltaScheduledWServer: number,
      isArmed: boolean
    ) {
      setIsMusicPlaying(false);
      setIsMusicFinished(false);

      if (isArmed) {
        // Create music nodes
        const newMusicNode = audioContext.createBufferSource();
        newMusicNode.buffer = musicBuffer;
        if (!gainNodeRef.current) {
          gainNodeRef.current = audioContext.createGain();
        }

        const splitter = audioContext.createChannelSplitter(2);
        const merger = audioContext.createChannelMerger(2);
        newMusicNode.connect(splitter);
        musicNodeRef.current = newMusicNode;

        // console.log(`TimeCode is : ${timecode}`)
        if (isTimecode) {
          if (isPyro) {
            // console.log('Timecode + Pyro')
            // console.log(`timecodeChannel : ${channelIndex(timecodeChannel)}`)
            // console.log(`!timecodeChannel : ${channelIndex(!timecodeChannel)}`)
            splitter.connect(
              merger,
              channelIndex(!timecodeChannel),
              channelIndex(!timecodeChannel)
            ); // Music goes to the non-timecode channel
            splitter.connect(
              merger,
              channelIndex(timecodeChannel),
              channelIndex(timecodeChannel)
            ); // Timecode goes to the specified timecode channel

            merger.connect(gainNodeRef.current).connect(audioContext.destination);
          } else {
            // console.log('Timecode sans pyro')
            // Route music to both channels if not pyro
            // console.log(`!timecodeChannel : ${!timecodeChannel}`)
            splitter.connect(
              merger,
              channelIndex(!timecodeChannel),
              channelIndex(!timecodeChannel)
            );
            splitter.connect(
              merger,
              channelIndex(!timecodeChannel),
              channelIndex(timecodeChannel)
            );
          }
        } else {
          // No timecode in the music
          splitter.connect(merger, 0, 0);
          splitter.connect(merger, 1, 1);
        }
        // Connect the merger to the gain node, then to the destination
        merger.connect(gainNodeRef.current).connect(audioContext.destination);

        // Calculate when the end of the music
        const timeoutDurationForEnd =
          (deltaScheduledWServer + musicBuffer.duration) * 1000;

        // console.log(` Calculating Playback delta
        // \n useEventAudio: (AudioContextCurrent Time) : ${audioContext.currentTime}
        // \n useEventAudio: (Delta before music plays (compared to AudioContext)) : ${playbackDelta}
        // \n useEventAudio: (Delta before music plays) : ${deltaScheduledWServer}
        // \n useEventAudio: (timeoutDurationForEnd) : ${timeoutDurationForEnd}
        // `);

        if (timeoutDurationForEnd > 0) {
          // Music has not ended yet
          if (playbackDelta > 0) {
            // music has not started yet
            // console.log(`useEventAudio:Music has not started yet`)
            newMusicNode.start(playbackDelta);
            timeoutRefStart.current = setTimeout(() => {
              // console.log('Start of the music')
              posthog?.capture('music_started', {
                isPyro: isPyro,
                musicPath: musicPath,
              });

              setIsMusicPlaying(true);
            }, deltaScheduledWServer * 1000);
          } // music has already started
          else {
            // console.log(`useEventAudio: Music has started, playing starting from : ${-playbackDelta}`)
            newMusicNode.start(0, -playbackDelta);
            setIsMusicPlaying(true);
          }
          // Update the state with the new source node
          setMusicNode(newMusicNode);

          // TimeOut when music Ends
          timeoutRefEnd.current = setTimeout(() => {
            posthog?.capture('music_ended', {
              isPyro: isPyro,
              musicPath: musicPath,
            });
            // console.log(`End of the Music`)
            setIsMusicPlaying(false);
            setIsMuted(false);
            setIsMusicFinished(true);
            // Stop nodes if necessary
            if (musicNode) {
              musicNode.stop();
            }
          }, timeoutDurationForEnd);
        } else {
          // console.log(`Music has ended already`)
          // Music already Ended
          setIsMusicPlaying(false);
          setIsMuted(false);
          setIsMusicFinished(true);
          // Stop sourceNode
          if (musicNode) {
            musicNode.stop();
          }
        }
      }
    }

    return () => {
      if (musicNode) {
        musicNode.stop();
      }
      // Cleanup timeOut
      if (timeoutRefStart.current) {
        clearTimeout(timeoutRefStart.current);
        timeoutRefStart.current = null;
      }
      if (timeoutRefEnd.current) {
        clearTimeout(timeoutRefEnd.current);
        timeoutRefEnd.current = null;
      }
    };
  }, [isMusicLoaded, deltaScheduledWServer, playbackDelta, isArmed]);

  // Logic to handle muting audioContext
  const toggleMute = () => {
    setIsMuted(prev => !prev);
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = isMuted ? 1 : 0;
    }
  };

  return { isMusicPlaying, isMusicFinished, toggleMute, isMuted, isMusicError };
};

export default useEventAudio;
