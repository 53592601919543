// useEventForm.ts

import { useState, useEffect, useContext } from 'react';
import { API } from '../../../utils/API';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getMusicErrorMessages, getImageErrorMessages } from '../../../utils/validation';
import { formatDate } from '../../../utils/formatUtils';
import { EventInfo } from '../../../types/eventTypes';

import { AuthContext } from '../../../contexts/AuthContext';
import useFileUpload from '../../../hooks/useFileUpload';
import { cleanFilename } from '../../../utils/fileUtils';

interface UseEventFormProps {
  isEditing: boolean;
  eventData?: EventInfo;
  onSuccess: (isSuccess: boolean) => void;
}

interface FormState {
  name: string;
  description: string;
  location: string;
  scheduledTime: Date | null;
  isTimecode: boolean;
  timecodeChannel: boolean;
  generatePublicLink: boolean;
}

interface FileUploadState {
  isUploading: boolean;
  uploadProgress: number;
  uploadErrorMessage: string | null;
  errorMessages: Record<string, string>;
}

const useEventForm = ({
  isEditing,
  eventData,
  onSuccess,
  // onError,
  // onFormChange,
}: UseEventFormProps) => {
  const { t } = useTranslation();
  const { userBusinessId } = useContext(AuthContext);

  const [formState, setFormState] = useState<FormState>({
    name: '',
    location: '',
    description: '',
    scheduledTime: null,
    isTimecode: false,
    timecodeChannel: false,
    generatePublicLink: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const [isLocationSelected, setIsLocationSelected] = useState<boolean>(false);
  const [publicLinkWarningMessage, setPublicLinkWarningMessage] =
    useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Generate error messages using the utility functions
  const musicErrorMessages = getMusicErrorMessages(t);
  const imageErrorMessages = getImageErrorMessages(t);

  // File upload states for music file
  const [musicFileUploadState, setMusicFileUploadState] = useState<FileUploadState>({
    isUploading: false,
    uploadProgress: 0,
    uploadErrorMessage: null,
    errorMessages: musicErrorMessages,
  });
  const [musicFile, setMusicFileState] = useState<File | null>(null);
  // File upload states for image file
  const [imageFileUploadState, setImageFileUploadState] = useState<FileUploadState>({
    isUploading: false,
    uploadProgress: 0,
    uploadErrorMessage: null,
    errorMessages: imageErrorMessages,
  });
  const [eventImageFile, setEventImageFileState] = useState<File | null>(null);

  const setMusicFile = (file: File | null) => {
    setMusicFileState(cleanFilename(file));
    setHasFormChanged(true);
  };

  const setEventImageFile = (file: File | null) => {
    setEventImageFileState(cleanFilename(file));
    setHasFormChanged(true);
  };

  const musicFileUpload = useFileUpload({
    userBusinessId,
    onProgress: progress => {
      setMusicFileUploadState(prevState => ({
        ...prevState,
        uploadProgress: progress,
        isUploading: true,
      }));
    },
    onError: error => {
      setMusicFileUploadState(prevState => ({
        ...prevState,
        uploadErrorMessage: error.message,
        isUploading: false,
      }));
    },
    onSuccess: filename => {
      setMusicFileUploadState(prevState => ({
        ...prevState,
        isUploading: false,
        uploadProgress: 0,
        uploadErrorMessage: null,
        errorMessages: musicErrorMessages,
      }));
    },
  });

  const imageFileUpload = useFileUpload({
    userBusinessId,
    onProgress: progress => {
      setImageFileUploadState(prevState => ({
        ...prevState,
        isUploading: true,
        uploadProgress: progress,
      }));
    },
    onError: error => {
      setImageFileUploadState(prevState => ({
        ...prevState,
        isUploading: false,
        uploadErrorMessage: error.message,
      }));
    },
    onSuccess: filename => {
      setImageFileUploadState(prevState => ({
        ...prevState,
        isUploading: false,
        uploadProgress: 0,
        uploadErrorMessage: null,
        errorMessages: imageErrorMessages,
      }));
    },
  });

  const resetForm = () => {
    setPublicLinkWarningMessage(false);
    setIsLocationSelected(false);
    setFormState({
      name: '',
      location: '',
      description: '',
      scheduledTime: null,
      isTimecode: false,
      timecodeChannel: false,
      generatePublicLink: false,
    });
    setMusicFile(null);
    setMusicFileUploadState({
      isUploading: false,
      uploadProgress: 0,
      uploadErrorMessage: null,
      errorMessages: musicErrorMessages,
    });
    setEventImageFile(null);
    setImageFileUploadState({
      isUploading: false,
      uploadProgress: 0,
      uploadErrorMessage: null,
      errorMessages: imageErrorMessages,
    });
    setHasFormChanged(false);
    setErrorMessages([]);
  };
  // Initialize form when editing an existing event
  useEffect(() => {
    resetForm();
    if (isEditing && eventData) {
      setFormState({
        name: eventData.name,
        description: eventData.description || '',
        location: eventData.location || '',
        scheduledTime: eventData.scheduledTime ? parseISO(eventData.scheduledTime) : null,
        isTimecode: eventData.isTimecode ?? false,
        timecodeChannel: eventData.timecodeChannel || false,
        generatePublicLink: false,
      });
      setIsLocationSelected(true);
    }
  }, [isEditing, eventData]);

  // Handle all form
  const handleInputChange = (field: keyof FormState, value: any) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
    setHasFormChanged(true);
  };
  // Handle location selection
  const handleLocationSelect = (location: string) => {
    setFormState(prevState => ({
      ...prevState,
      location,
    }));
    setIsLocationSelected(true);
    setHasFormChanged(true);
  };

  // Handle uploading files

  const uploadFiles = async () => {
    let musicUploadSuccess = false;
    let imageUploadSuccess = false;

    if (musicFile) {
      musicUploadSuccess = await musicFileUpload.uploadFile(musicFile);
    }
    if (eventImageFile) {
      imageUploadSuccess = await imageFileUpload.uploadFile(eventImageFile);
    }

    if (!musicUploadSuccess || !imageUploadSuccess) {
      return false;
    }
    return true;
  };

  const createEvent = async () => {
    setIsLoading(true);

    const uploadSuccess = await uploadFiles();
    if (!uploadSuccess) {
      setErrorMessages([t('unexpectedUploadError')]);
      setIsLoading(false);
      return;
    }

    const newEventData = {
      ...formState,
      scheduledTime: formState.scheduledTime?.toISOString(),
      businessId: userBusinessId,
      originalMusicName: musicFile?.name || '',
      originalEventImageName: eventImageFile?.name || '',
    };

    try {
      console.log('Creating event');
      await API.post(`/api/v1/events`, newEventData, {
        withCredentials: true,
      });
      resetForm();
      onSuccess(true);
    } catch (error: any) {
      handleEventAPIErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateEvent = async () => {
    if (!eventData) return;
    setIsLoading(true);

    const updatedFields: Partial<FormState> = {};

    // Compare each field with eventData and add to updatedFields if changed
    if (formState.name !== eventData.name) {
      updatedFields.name = formState.name;
    }
    if (formState.location !== eventData.location) {
      updatedFields.location = formState.location;
    }

    if (formState.scheduledTime?.toISOString() !== eventData.scheduledTime) {
      updatedFields.scheduledTime = formState.scheduledTime;
    }

    if (formState.description !== eventData.description) {
      updatedFields.description = formState.description;
    }

    if (
      formState.isTimecode !== eventData.isTimecode ||
      formState.timecodeChannel !== eventData.timecodeChannel
    ) {
      updatedFields.isTimecode = formState.isTimecode;
      updatedFields.timecodeChannel = formState.timecodeChannel;
    }

    updatedFields.generatePublicLink = formState.generatePublicLink;

    if (musicFile && musicFile.name !== eventData.originalMusicName) {
      const musicUploadSuccess = await musicFileUpload.uploadFile(musicFile);
      if (!musicUploadSuccess) {
        setErrorMessages([t('unexpectedUploadError')]);
        setIsLoading(false);
        return;
      }
    }

    if (eventImageFile && eventImageFile.name !== eventData.originalEventImageName) {
      const imageUploadSuccess = await imageFileUpload.uploadFile(eventImageFile);
      if (!imageUploadSuccess) {
        setErrorMessages([t('unexpectedUploadError')]);
        setIsLoading(false);
        return;
      }
    }

    if (Object.keys(updatedFields).length === 0) {
      setIsLoading(false);
      onSuccess(false);
      return;
    }

    try {
      await API.patch(`/api/v1/events/${eventData.id}`, updatedFields, {
        withCredentials: true,
      });
      onSuccess(true);
    } catch (error: any) {
      handleEventAPIErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEventAPIErrors = (error: any) => {
    if (error === 'FILE_UPLOAD_ERROR') {
      setErrorMessages([t('unexpectedUploadError')]);
    } else if (error.response?.data?.error === 'EVENT_IN_PAST') {
      setErrorMessages([t('eventInPastError')]);
    } else if (error.response?.data?.error === 'EVENT_ALREADY_EXISTS') {
      setErrorMessages([
        t('eventAlreadyExists', {
          location: error.response.data.details.location,
          date: formatDate(error.response.data.details.date),
        }),
      ]);
    } else {
      setErrorMessages([t('unexpectedError')]);
    }
    onSuccess(false);
  };
  // Determine if the submit button should be enabled
  const isSubmitEnabled = (): boolean => {
    // Common validation: location must be selected, scheduledTime must not be null, name must not be empty
    const commonValidation = isLocationSelected && formState.scheduledTime !== null;

    if (!commonValidation) {
      return false;
    }
    // If not editing the files should be selected
    return isEditing ? hasFormChanged : musicFile !== null && eventImageFile !== null;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isEditing) {
      await updateEvent();
    } else {
      await createEvent();
    }
  };

  return {
    formState,
    handleInputChange,
    handleLocationSelect,
    handleSubmit,
    setMusicFile,
    musicFileUploadState,
    setEventImageFile,
    imageFileUploadState,
    publicLinkWarningMessage,
    setPublicLinkWarningMessage,
    isLoading,
    errorMessages,
    isSubmitEnabled,
  };
};

export default useEventForm;
