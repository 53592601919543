// components/ShareModalComponent.js
import React, { useState } from 'react';
import {
  Modal,
  ModalDialog,
  DialogContent,
  Button,
  Stack,
  DialogTitle,
  Box,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../utils/formatUtils';

// import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import useBusinessInfo from '../../../hooks/useBusinessInfo';
import { EventInfo } from '../../../types/eventTypes';
import LinkSectionComponent from '../../../components/LinkSectionComponent';

interface ShareModalComponentProps {
  open: boolean;
  handleClose: () => void;
  eventData: EventInfo;
  userBusinessId: string | null;
}

const ShareModalComponent: React.FC<ShareModalComponentProps> = ({
  open,
  handleClose,
  eventData,
  userBusinessId,
}) => {
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
  const { t } = useTranslation();

  const { businessInfo } = useBusinessInfo(`admin/${userBusinessId}`);

  const eventInfo = `${eventData.location} - ${
    eventData.scheduledTime
      ? formatDate(eventData.scheduledTime)
      : t('scheduledTimeNotFound')
  }`;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="share-modal-title"
      sx={{ backdropFilter: 'blur(10px)' }}
    >
      <ModalDialog variant="outlined" color="primary" sx={{ maxWidth: '450px' }}>
        <DialogTitle>
          {t('shareEventLongTitle', {
            location: eventData.location,
            date: formatDate(eventData.scheduledTime!),
          })}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2 }}
          >
            {/* Public Link Section */}
            <LinkSectionComponent
              title={t('publicLinkTitle')}
              link={`${frontendUrl}/events/${eventData.publicLink || ''}`}
              imageName={eventData.publicLink || 'event'}
              QRCodeTitle={eventInfo}
              businessLogo={businessInfo?.logoPath!}
            />
            {/* Pyro Link Section */}
            <LinkSectionComponent
              title={t('pyroLinkTitle')}
              link={`${frontendUrl}/events/pyro/${eventData.id || ''}`}
              imageName={`orga_${eventData.publicLink || 'event'}`}
              QRCodeTitle={eventInfo}
              businessLogo={businessInfo?.logoPath!}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="plain" color="neutral" onClick={handleClose}>
                {t('close')}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ShareModalComponent;
