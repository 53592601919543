import React, { useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import { CircularProgress, Typography } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import engb from 'date-fns/locale/en-GB';
import axios from 'axios';

import { AuthProvider } from './contexts/AuthContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { ProtectedRoute } from './utils/ProtectedRoute';

import NotFoundPage from './views/notfoundpage';

import PublicPage from './views/publicpage';
import PyroPage from './views/pyropage/pyropage';
import TestPyroPage from './views/pyropage/testpyropage';

import AdminEventsPage from './views/adminevents/admineventspage';
import AdminUsersPage from './views/adminusers/adminuserspage';
import AdminBusinessesPage from './views/adminbusiness/adminbusinessespage';
import AdminSettingsPage from './views/adminsettings/adminsettingspage';

import SigninPage from './views/signin/signinpage';
import OnBoardingPage from './views/onboarding/onboardingpage';
import GlobalSettingsPage from './views/globalsettings/globalsettingspage';
import EventPaymentSuccessPage from './views/eventPaymentSuccesspage';
import EventPaymentErrorPage from './views/eventPaymentErrorpage';
import BusinessEventsPage from './views/eventsbybusiness/eventsbybusinesspage';
import SearchEventPage from './views/searchevent/searcheventpage';

import './index';
import './App.css';

const queryClient = new QueryClient();

const App = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const audioContextRef = useRef(null);

  // Check if server is up before launching application
  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        await axios.get('/server-status');
      } catch (err) {
        setError('Server is down. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    checkServerStatus();
  }, []);

  if (isLoading || error) {
    return (
      <div className="flex items-center justify-center h-screen">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Typography>{"Can't reach server or error"} </Typography>
        )}
      </div>
    );
  }

  if (!audioContextRef.current) {
    // console.log('Creating AudioContext');
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
  }

  // Defining router outside APP if the routes are static and don't need to change during the application's lifecycle
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AuthProvider>
          <Navigate to="/signin" replace />,
        </AuthProvider>
      ),
    },
    {
      path: '/signin',
      element: (
        <AuthProvider>
          <SigninPage />
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/search',
      element: (
        <AuthProvider isPublic={true}>
          {/* <WebSocketProvider> */}
          <SearchEventPage audioContext={audioContextRef.current} />
          {/* </WebSocketProvider> */}
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/events/:publicLink',
      element: (
        <AuthProvider isPublic={true}>
          <WebSocketProvider>
            <PublicPage audioContext={audioContextRef.current} />
          </WebSocketProvider>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/events/pyro/:eventId',
      element: (
        <AuthProvider isPublic={true}>
          <WebSocketProvider>
            <PyroPage audioContext={audioContextRef.current} />
          </WebSocketProvider>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/events/:publicLink/:businessShortName',
      element: (
        <AuthProvider isPublic={true}>
          <WebSocketProvider>
            <TestPyroPage audioContext={audioContextRef.current} />
          </WebSocketProvider>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/business/:businessShortName',
      element: (
        <AuthProvider isPublic={true}>
          <BusinessEventsPage />
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/events',
      element: (
        <AuthProvider>
          <WebSocketProvider>
            <ProtectedRoute requiredRole="admin">
              <AdminEventsPage />
            </ProtectedRoute>
          </WebSocketProvider>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/payment/success',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="admin">
            <EventPaymentSuccessPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/payment/error',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="admin">
            <EventPaymentErrorPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/onboarding',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="admin">
            <OnBoardingPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/users',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="super-admin">
            <AdminUsersPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/businesses',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="super-admin">
            <AdminBusinessesPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/admin/settings',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="super-admin">
            <GlobalSettingsPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: '/settings',
      element: (
        <AuthProvider>
          <ProtectedRoute requiredRole="admin">
            <AdminSettingsPage />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
  ]);

  // document.addEventListener("visibilitychange", function() {
  //   if (document.visibilityState === 'visible') {
  //     console.log('Page is now active');
  //     // Reinitialize your application state here or refresh data
  //   }
  // });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={engb}>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </div>
    </LocalizationProvider>
  );
};

export default App;
