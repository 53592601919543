// EventModalComponent.tsx

import React from 'react';
import {
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Button,
  Input,
  Textarea,
  Stack,
  FormControl,
  Typography,
  Box,
  Sheet,
  Switch,
  CircularProgress,
} from '@mui/joy';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useTranslation } from 'react-i18next';

import CustomFormLabel from '../../components/CustomFormLabel';
import FileUploadComponent from '../../components/FileUploadComponent';
import LocationComponent from './components/LocationComponent';
import useEventForm from './hooks/useEventForm';

import { EventInfo } from '../../types/eventTypes';

interface EventModalComponentProps {
  open: boolean;
  handleClose: (isSuccess: boolean) => void;
  isEditing: boolean;
  eventData?: EventInfo | undefined;
}

const EventModalComponent: React.FC<EventModalComponentProps> = ({
  open,
  handleClose,
  isEditing,
  eventData,
}) => {
  const { t } = useTranslation();

  const {
    formState,
    handleInputChange,
    handleLocationSelect,
    handleSubmit,
    setMusicFile,
    musicFileUploadState,
    setEventImageFile,
    imageFileUploadState,
    publicLinkWarningMessage,
    setPublicLinkWarningMessage,
    isLoading,
    errorMessages,
    isSubmitEnabled,
  } = useEventForm({
    isEditing,
    eventData,
    onSuccess: handleClose,
  });

  // Combine error messages into a single string
  const finalErrorMessage = errorMessages.join('\n');

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="event-modal-title"
      aria-describedby="event-modal-description"
      sx={{ backdropFilter: 'blur(10px)' }}
    >
      <ModalDialog variant="outlined" color="primary">
        <DialogTitle>
          {isEditing ? t('editEventTitle') : t('createEventTitle')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              {/* Location Component */}
              <FormControl>
                <LocationComponent
                  onLocationSelect={handleLocationSelect}
                  isEditing={isEditing}
                  initialLocation={formState.location}
                  setPublicLinkWarningMessage={setPublicLinkWarningMessage}
                />
              </FormControl>

              {/* Event Name */}
              <FormControl>
                <CustomFormLabel
                  htmlFor="event-name"
                  label={t('name')}
                  optional={true}
                  flyover={true}
                  flyoverText={t('nameDescription')}
                />
                <Input
                  id="event-name"
                  value={formState.name}
                  onChange={e => handleInputChange('name', e.target.value)}
                  variant="outlined"
                />
              </FormControl>

              {/* Event Description */}
              <FormControl>
                <CustomFormLabel
                  htmlFor="event-description"
                  label={t('description')}
                  optional={true}
                  flyover={false}
                />
                <Textarea
                  id="event-description"
                  value={formState.description}
                  onChange={e => handleInputChange('description', e.target.value)}
                  minRows={4}
                  maxRows={6}
                />
              </FormControl>

              {/* Image Upload */}
              <FormControl>
                <CustomFormLabel
                  htmlFor="event-image"
                  label={t('uploadImage')}
                  optional={false}
                  flyover={true}
                  flyoverText={t('imageDescription')}
                />
                <FileUploadComponent
                  label={t('imageFile')}
                  variant="plain"
                  color="neutral"
                  allowedTypes={['image/jpeg', 'image/png', 'image/gif']}
                  maxSizeMB={100}
                  initialFileName={(isEditing && eventData?.originalEventImageName) || ''}
                  onFileSelected={file => setEventImageFile(file)}
                  isUploading={imageFileUploadState.isUploading}
                  uploadProgress={imageFileUploadState.uploadProgress}
                  uploadErrorMessage={imageFileUploadState.uploadErrorMessage}
                  errorMessages={imageFileUploadState.errorMessages}
                />
              </FormControl>

              {/* Event Date */}
              <FormControl>
                <CustomFormLabel
                  htmlFor="event-date"
                  label={t('date')}
                  optional={false}
                  flyover={true}
                  flyoverText={t('dateDescription')}
                />
                <MobileDateTimePicker
                  label=" "
                  value={formState.scheduledTime}
                  onChange={newValue => {
                    handleInputChange('scheduledTime', newValue);
                    if (isEditing) {
                      setPublicLinkWarningMessage(true);
                    }
                  }}
                  minDate={new Date()}
                />
              </FormControl>

              {/* Music Upload */}
              <FormControl>
                <CustomFormLabel
                  htmlFor="event-music"
                  label={t('uploadMusic')}
                  optional={false}
                  flyover={true}
                  flyoverText={t('musicDescription')}
                />
                <FileUploadComponent
                  label={t('musicFile')}
                  variant="soft"
                  color="primary"
                  allowedTypes={['audio/wav', 'audio/mpeg']}
                  maxSizeMB={200}
                  onFileSelected={file => setMusicFile(file)}
                  initialFileName={(isEditing && eventData?.originalMusicName) || ''}
                  isUploading={musicFileUploadState.isUploading}
                  uploadProgress={musicFileUploadState.uploadProgress}
                  uploadErrorMessage={musicFileUploadState.uploadErrorMessage}
                  errorMessages={musicFileUploadState.errorMessages}
                />
              </FormControl>

              {/* TimeCode Option */}
              <Box>
                <FormControl>
                  <CustomFormLabel
                    htmlFor="event-timecode"
                    label={t('timeCode')}
                    optional={true}
                    flyover={true}
                    flyoverText={t('timecodeDescription')}
                  />
                  <Typography
                    component="label"
                    sx={{ flexGrow: 1, textAlign: 'left' }}
                    startDecorator={
                      <Switch
                        variant={formState.isTimecode ? 'solid' : 'outlined'}
                        onChange={e => handleInputChange('isTimecode', e.target.checked)}
                        checked={formState.isTimecode}
                      />
                    }
                  >
                    {t('timeCode')}
                  </Typography>
                </FormControl>
                {!!formState.isTimecode && (
                  <FormControl>
                    <CustomFormLabel
                      htmlFor="event-timecodechannel"
                      label={t('timecodeChannel')}
                      optional={false}
                      flyover={true}
                      flyoverText={t('timecodeChannelDescription')}
                    />
                    <Switch
                      variant="solid"
                      onChange={e =>
                        handleInputChange('timecodeChannel', e.target.checked)
                      }
                      checked={formState.timecodeChannel}
                      startDecorator={
                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                          <Typography component="label">
                            <span className="material-symbols-outlined">speaker</span>
                          </Typography>
                          <Typography component="label">L</Typography>
                        </Box>
                      }
                      endDecorator={
                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
                          <Typography component="label">
                            <span className="material-symbols-outlined">speaker</span>
                          </Typography>
                          <Typography component="label">R</Typography>
                        </Box>
                      }
                    />
                  </FormControl>
                )}
              </Box>

              {/* Public Link Warning */}
              {publicLinkWarningMessage && (
                <Sheet
                  color="warning"
                  variant="soft"
                  sx={{
                    maxWidth: '450px',
                    p: 2,
                    borderRadius: 'sm',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1,
                  }}
                >
                  <Typography color="warning" sx={{ textAlign: 'left' }}>
                    {t('updatePublicLink')}
                  </Typography>
                  <Typography
                    color="warning"
                    component="label"
                    sx={{ flexGrow: 1, textAlign: 'left' }}
                    endDecorator={
                      <Switch
                        variant="solid"
                        onChange={e =>
                          handleInputChange('generatePublicLink', e.target.checked)
                        }
                        checked={formState.generatePublicLink}
                        sx={{ ml: 1 }}
                      />
                    }
                  >
                    {t('generatePublicLink')}
                  </Typography>
                </Sheet>
              )}

              {/* Error Messages */}
              {finalErrorMessage && (
                <Sheet
                  color="danger"
                  variant="soft"
                  sx={{ px: 2, py: 1, borderRadius: 'sm' }}
                >
                  {finalErrorMessage.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Sheet>
              )}

              {/* Submit Buttons */}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                  variant="plain"
                  color="neutral"
                  disabled={isLoading}
                  onClick={() => handleClose(false)}
                >
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={!isSubmitEnabled() || isLoading}
                  loading={isLoading}
                  loadingPosition="start"
                >
                  {isEditing ? t('save') : t('create')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default EventModalComponent;
