import { useState, useEffect } from 'react';
import { API } from '../utils/API';
import { BusinessInfo } from '../types/businessTypes';

interface BusinessInfoReturn {
  businessInfo: BusinessInfo | null;
  isBusinessLoading: boolean;
  error: any;
}

export default function useBusinessInfo(
  apiName: string | null // admin/businessId or adminname/businessShortname
): BusinessInfoReturn {
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo | null>(null);
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchBusinessInformation = async () => {
    try {
      const response = await API.get<BusinessInfo>(`/api/v1/businesses/${apiName}`, {
        withCredentials: true,
      });
      console.log(response.data);
      setBusinessInfo(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsBusinessLoading(false);
    }
  };

  useEffect(() => {
    if (apiName) {
      fetchBusinessInformation();
    }
  }, [apiName]);

  return { businessInfo, isBusinessLoading, error };
}
