// pyropage.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { CssBaseline, Box, CircularProgress } from '@mui/joy';

// Custom Hooks
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import useEvent from '../../hooks/useEvent';
import useNotification from '../../hooks/useNotification';
import useAudioContextState from '../../hooks/useAudioContextState';
import useTestSound from '../../hooks/useTestSound';
import useTriggerNotification from './hooks/useTriggerNotification';
import useBusinessInfo from '../../hooks/useBusinessInfo';

// Custom Components
import EventInfoComponent from '../../components/EventInfoComponent';
import MusicPlayerComponent from '../../components/MusicPlayerComponent';
import WelcomeModalComponent from '../../components/WelcomeModalComponent';
import TestSoundModalComponent from '../../components/TestSoundModalComponent';
import BrandFooterComponent from '../../components/BrandFooterComponent';
import EventStatusSnackBarComponent from '../../components/EventStatusSnackBarComponent';

import CountdownComponent from './components/CountDownComponent';
import TimeCodeInfoComponent from './components/TimeCodeInfoComponent';

interface TestPyroPageProps {
  audioContext: AudioContext;
}

const TestPyroPage: React.FC<TestPyroPageProps> = ({ audioContext }) => {
  const { publicLink, businessShortName } = useParams<{
    publicLink: string;
    businessShortName: string;
  }>();
  const { mode } = useThemeSwitcher();

  const navigate = useNavigate();

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  // Audio
  const { audioContextState, isWelcomeModalOpen, handleAudioContextResume } =
    useAudioContextState(audioContext);

  // Get Event information (useEventInfo / useEventAudio / useNTPTime)
  const {
    eventInfo,
    scheduledTime,
    testTime,
    isArmed,
    eventError,
    ntpOffset,
    isSynchronized,
    isMusicPlaying,
    isMusicFinished,
    toggleMute,
    isMuted,
    isMusicError,
    isNetworkError,
  } = useEvent({
    eventIdentifier: publicLink!,
    audioContext,
    audioContextState,
    isTestMode: true,
    isPyroPage: false,
  });

  const { businessInfo, isBusinessLoading } = useBusinessInfo(
    `admin/${eventInfo?.businessId}`
  );

  const { requestPermission } = useNotification(eventInfo?.id!);

  const {
    isTestSoundModalOpen,
    testSound,
    closeTestSoundModal,
    error: testSoundError,
    isTestPlaying,
  } = useTestSound();

  // Navigate to public page if launched
  useEffect(() => {
    if (eventInfo?.isArmed) {
      navigate(`/events/${eventInfo?.publicLink}`);
    }
  }, [eventInfo?.isArmed]);

  // Check if the path is OK
  useEffect(() => {
    if (!isBusinessLoading) {
      // console.log('BusinessInfo is OK!');
      // console.log(businessInfo);
      if (businessInfo && businessInfo?.shortName !== businessShortName) {
        navigate(`/events/${eventInfo?.publicLink}`);
      }
    }
  }, [businessInfo]);

  // Check if businessShortName matches
  if (isBusinessLoading) {
    return <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />;
  }

  return (
    <>
      <EventStatusSnackBarComponent
        isSynchronized={isSynchronized}
        isNetworkError={isNetworkError}
      />
      <WelcomeModalComponent
        open={isWelcomeModalOpen}
        onHandleAudioAndNotification={async () => {
          // Resume audio context when modal is closed and ask for permission & activate
          await handleAudioContextResume();
          await requestPermission();
        }}
        onHandleAudioContext={handleAudioContextResume}
        isPyro={false}
      />
      <CssBaseline />
      <Box
        sx={{
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          // justifyContent: 'space-between',
          flexGrow: 1,
          textAlign: 'left',
          width: '100%',
          maxWidth: '640px',
          mx: 'auto',
          backgroundColor: theme =>
            mode === 'dark' ? theme.palette.warning[500] : theme.palette.warning[50],
        }}
      >
        {/* Event Info */}
        {eventInfo && (
          <EventInfoComponent
            eventInfo={eventInfo}
            displayImage={eventInfo?.isPassed ? true : false}
            displayDescription={eventInfo?.isPassed ? true : false}
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
            testSound={testSound}
            mode={mode}
            isPyroPage={false} // Test Page provide Public page when shared
          />
        )}
        {/* Countdown*/}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'left',
            flexGrow: 1,
            p: 2,
          }}
        >
          {/* Show countDown only if testTime is not null */}
          {!!(!eventError && !eventInfo?.isPassed && testTime) && (
            <CountdownComponent ntpOffset={ntpOffset!} scheduledTime={testTime!} />
          )}
        </Box>

        {/* Show Brand Footer + MusicPlayerComponent & TimeCodeComponent if isTimeCode True */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            position: 'sticky',
            // p: 2,
            gap: 1,
            bottom: '0',
            zIndex: 10,
          }}
        >
          <BrandFooterComponent mode={mode} showDate={false} />
          {/* Music Player conditionnaly rendering depending on error state */}
          {!eventError && !eventInfo?.isPassed && (
            <>
              <TimeCodeInfoComponent
                isTimecode={eventInfo?.isTimecode || false}
                timecodeChannel={eventInfo?.timecodeChannel || false}
              />
              {/* MusicPlayer */}
              {/* Display only MusicPlayer if isSynchronized & no network error */}
              {isSynchronized && !isNetworkError && testTime && (
                <MusicPlayerComponent
                  isMusicPlaying={isMusicPlaying}
                  isMusicFinished={isMusicFinished}
                  isMuted={isMuted}
                  isMusicError={isMusicError}
                  scheduledTime={testTime}
                  toggleMute={toggleMute}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {/* The Modal to test the sound */}
      <TestSoundModalComponent
        open={isTestSoundModalOpen}
        close={closeTestSoundModal}
        replaySound={testSound}
        isTestPlaying={isTestPlaying}
      />
    </>
  );
};

export default TestPyroPage;
