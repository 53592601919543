// pyropage.tsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CssBaseline, Button, Box } from '@mui/joy';

// Custom Hooks
import useThemeSwitcher from '../../hooks/useThemeSwitcher';
import useEvent from '../../hooks/useEvent';
import useNotification from '../../hooks/useNotification';
import useAudioContextState from '../../hooks/useAudioContextState';
import useTestSound from '../../hooks/useTestSound';
import useLaunchEvent from './hooks/useLaunchEvent';
import useTriggerNotification from './hooks/useTriggerNotification';

// Custom Components
import EventInfoComponent from '../../components/EventInfoComponent';
import MusicPlayerComponent from '../../components/MusicPlayerComponent';
import WelcomeModalComponent from '../../components/WelcomeModalComponent';
import TestSoundModalComponent from '../../components/TestSoundModalComponent';
import BrandFooterComponent from '../../components/BrandFooterComponent';
import EventStatusSnackBarComponent from '../../components/EventStatusSnackBarComponent';

import CountdownComponent from './components/CountDownComponent';
import ChangeScheduledTimeDrawerComponent from './components/ChangeScheduledTimeDrawerComponent';
import TimeCodeInfoComponent from './components/TimeCodeInfoComponent';
import PyroTabsComponent from './components/PyroTabsComponent';
import PyroInfoComponent from './components/PyroInfoComponent';

interface PyroPageProps {
  audioContext: AudioContext;
}

const PyroPage: React.FC<PyroPageProps> = ({ audioContext }) => {
  const { eventId } = useParams<string>();
  const { t } = useTranslation();
  const { mode, toggleTheme } = useThemeSwitcher();

  // const [isArmed, setIsArmed] = useState<boolean>(false);
  const [isTestMode, setisTestMode] = useState<boolean>(true); // Per default is in test
  // To make the switch locally correct
  const [isArmedLocal, setIsArmedLocal] = useState<boolean>(false);
  // is launched = event.isArmed = true or isArmedLocal & new scheduled Time
  const [isLaunched, setIsLaunched] = useState<boolean>(false);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // Audio
  const { audioContextState, isWelcomeModalOpen, handleAudioContextResume } =
    useAudioContextState(audioContext);

  // Get Event information (useEventInfo / useEventAudio / useNTPTime)
  const {
    eventInfo,
    scheduledTime,
    testTime,
    isArmed,
    eventError,
    ntpOffset,
    isSynchronized,
    isMusicPlaying,
    isMusicFinished,
    toggleMute,
    isMuted,
    isMusicError,
    isNetworkError,
  } = useEvent({
    eventIdentifier: eventId!,
    audioContext,
    audioContextState,
    isTestMode,
    isPyroPage: true,
  });

  const { requestPermission } = useNotification(eventInfo?.id!);

  // UpdateEvent Scheduled or Test time depending on isArmed and testMode
  const { updateEventLaunchedTime } = useLaunchEvent(eventId!, isTestMode, setIsLaunched);

  const { triggerWebPushNotification } = useTriggerNotification();

  const {
    isTestSoundModalOpen,
    testSound,
    closeTestSoundModal,
    error: testSoundError,
    isTestPlaying,
  } = useTestSound();

  // Set to Public mode if the event is already Armed
  useEffect(() => {
    if (eventInfo?.isArmed) {
      setisTestMode(false);
      setIsArmedLocal(eventInfo?.isArmed);
      setIsLaunched(true);
    } else if (isTestMode) {
      // console.log('reseting testmode');
      // Reset isTestMode
      setIsArmedLocal(false);
      updateEventLaunchedTime(null, false, false); // reset isLaunched
    }
  }, [eventInfo?.isArmed]);

  useEffect(() => {
    // console.log(`isTestMode changes : ${isTestMode}`);
    // console.log(`isTestMode changes : ${isArmed}`);
    // console.log(`isTestMode changes : ${isLaunched}`);
    if (isTestMode) {
      // Reset launchedTime and isArmed when switching to testMode
      // updateEventLaunchedTime(null, false, false); // reset isLaunched
      setIsLaunched(false);
    } else {
      // if public mode : set back isArmed & LaunchedTime accordingly
      setIsLaunched(isArmed);
      setIsArmedLocal(isArmed);
    }
  }, [isTestMode]);

  // Reset launchedTime and isArmed when switching Armed
  const handleArmed = (isArmed: boolean) => {
    if (!isArmed && isLaunched) {
      // It was armed & launchedTime was set -> Reset Public ScheduledTime in the database
      updateEventLaunchedTime(scheduledTime, false);
      setIsLaunched(false);
      setIsArmedLocal(false);
    } else if (!isArmed) {
      setIsArmedLocal(false);
    } else {
      // if isArmed is true and isLaunched is false
      setIsArmedLocal(true);
    }
  };

  const handleLaunchMusic = () => {
    // to launch music
    if (!isLaunched) {
      setDrawerOpen(true);
      return;
    }

    if (isTestMode) {
      // to stop music in testmode
      // isTestMode and isLaunched true
      updateEventLaunchedTime(null, false, false); // reset isLaunched
      setIsArmedLocal(false);
    } else {
      // to stop music in public when isLaunched is true
      // If Public mode
      handleArmed(false);
    }
  };

  // useEffect(() => {
  //   const info = () => {
  //     console.log(`interval isLaunched: ${isLaunched}`);
  //     console.log(`interval isArmed: ${isArmed}`);
  //     console.log(`interval isTestMode: ${isTestMode}`);
  //   };
  //   let timerInterval: NodeJS.Timeout | undefined;
  //   clearInterval(timerInterval);
  //   timerInterval = setInterval(info, 10000);
  // });

  return (
    <>
      <EventStatusSnackBarComponent
        isSynchronized={isSynchronized}
        isNetworkError={isNetworkError}
      />
      <WelcomeModalComponent
        open={isWelcomeModalOpen}
        onHandleAudioAndNotification={async () => {
          // Resume audio context when modal is closed and ask for permission & activate
          await handleAudioContextResume();
          await requestPermission();
        }}
        onHandleAudioContext={handleAudioContextResume}
        isPyro={true}
      />
      <CssBaseline />
      <Box
        sx={{
          height: '100%',
          width: '100%',
          top: '0',
          position: 'absolute',
          backgroundColor: theme =>
            isTestMode
              ? mode === 'dark'
                ? theme.palette.warning[500]
                : theme.palette.warning[50]
              : theme.palette.background.body,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          // justifyContent: 'space-between',
          flexGrow: 1,
          textAlign: 'left',
          width: '100%',
          maxWidth: '640px',
          mx: 'auto',
          backgroundColor: theme =>
            isTestMode
              ? mode === 'dark'
                ? theme.palette.warning[500]
                : theme.palette.warning[50]
              : theme.palette.background.body,
          zIndex: 100,
        }}
      >
        {/* Tabs from test / public */}
        {!eventError && (
          <PyroTabsComponent isTestMode={isTestMode} setIsTestMode={setisTestMode} />
        )}
        {/* Event Info */}
        {eventInfo && (
          <EventInfoComponent
            eventInfo={eventInfo}
            displayImage={eventInfo?.isPassed ? true : false}
            displayDescription={eventInfo?.isPassed ? true : false}
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
            testSound={testSound}
            mode={mode}
            isPyroPage={true} // For the share modal component
          />
        )}
        {!eventError && eventInfo && (
          <PyroInfoComponent
            eventInfo={eventInfo}
            isTestMode={isTestMode}
            isArmed={isArmedLocal!}
            handleArmed={handleArmed}
            isLaunched={isLaunched} // || testTime !== null To modify text in button
            handleLaunchMusic={handleLaunchMusic}
          />
        )}
        {/* Countdown*/}
        {/* Show Countdown only if no errror, event is not passed and either isLaunched or TestTime not null */}
        {!!(!eventError && !eventInfo?.isPassed && (isLaunched || testTime)) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
              flexGrow: 1,
              p: 2,
            }}
          >
            <CountdownComponent
              ntpOffset={ntpOffset!}
              scheduledTime={isTestMode ? testTime! : scheduledTime!}
            />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            position: 'sticky',
            // p: 2,
            gap: 1,
            bottom: '0',
            zIndex: 10,
          }}
        >
          {!eventError && (
            <>
              {/* Disable notification notification in test Mode */}
              <Button
                onClick={() => triggerWebPushNotification(eventId!)}
                disabled={isTestMode}
                variant="outlined"
                color="neutral"
              >
                {'Send notification'}
              </Button>
            </>
          )}

          <BrandFooterComponent mode={mode} showDate={false} />
          {/* Music Player conditionnaly rendering depending on error state */}
          {!eventError && !eventInfo?.isPassed && (
            <>
              <TimeCodeInfoComponent
                isTimecode={eventInfo?.isTimecode || false}
                timecodeChannel={eventInfo?.timecodeChannel || false}
              />
              {/* MusicPlayer */}
              {/* Display only MusicPlayer 
                if isSynchronized & no network error 
                If in TestMode, then don't display the musicplayer if testTime = null (the user reset the test music)
                MusicPlayer component is displayed anyway in public mode 
                */}
              {isSynchronized &&
                !isNetworkError &&
                (!isTestMode || testTime != null) && ( // In test mode, testTime must not be null (reset by the user)
                  <MusicPlayerComponent
                    isMusicPlaying={isMusicPlaying}
                    isMusicFinished={isMusicFinished}
                    isMuted={isMuted}
                    isMusicError={isMusicError}
                    scheduledTime={isTestMode ? testTime : scheduledTime}
                    toggleMute={toggleMute}
                  />
                )}
            </>
          )}
        </Box>
      </Box>

      <TestSoundModalComponent
        open={isTestSoundModalOpen}
        close={closeTestSoundModal}
        replaySound={testSound}
        isTestPlaying={isTestPlaying}
      />
      <ChangeScheduledTimeDrawerComponent
        open={drawerOpen}
        handleClose={() => setDrawerOpen(false)}
        updateEventLaunchedTime={updateEventLaunchedTime}
        scheduledTime={isTestMode ? testTime! : scheduledTime!}
        ntpOffset={ntpOffset!}
        isTest={isTestMode}
        isArmed={isArmedLocal}
      />
    </>
  );
};

export default PyroPage;
